export class TableTyrantGrid {
  constructor(el) {
    this._el = $(el);
    this._setupListeners();
    this._makeDraggable();
  }

  get id() { return this._el.attr('id') }
  get workspaces() { return this._workspaces || (this._workspaces = new TableTyrant.Workspaces(this)) }
  get filters() { return this._filters || (this._filters = new TableTyrant.Filters(this)) }
  get columns() { return this._columns || (this._columns = new TableTyrant.Columns(this._el)) }
  get sorts() { return this._sorts || (this._sorts = new TableTyrant.Sorts(this)) }
  get form() { return this._el.find('.edit-workspace') }

  get liveUpdatesUrl() {
    let url = this._el.attr('data-live-updates-url');
    return url ? new URL(url, document.location) : new URL(document.location);
  }
  
  url(params={}, baseUrl=null) {
    let url = baseUrl ? new URL(baseUrl, document.location) : this.liveUpdatesUrl;
    for (let [name, value] of Object.entries(params)) {
      if (Array.isArray(value)) {
        value.forEach(v => url.searchParams.append(name, v));
      } else if (value) {
        url.searchParams.set(name, value);
      } else {
        url.searchParams.delete(name);
      }
    }
    return url;
  }

  // events
  event(action, value) {
    if (value && value.toLowerCase) {value = value.toLowerCase().replace(/[^a-z0-9]+/g, '-')}
    if (value && value[0] === '-') {value = this.id + value}
    if (value === undefined) {value = this.id}
    
    analytics.event('table-tyrant', action, value);
    return this;
  }

  // history
  rememberInitialState() {
    history.replaceState({
      ...(history.state || {}),
      tableTyrantId: this.id,
      formData: this.form.serializeArray()
    }, null, this._urlWithoutGimme(document.location.href));
  }

  updateUrl(url) {
    history.pushState({
      tableTyrantId: this.id,
      updatesUrl: url
    }, null, this._urlWithoutGimme(url));
  }

  updateUrlFromForm() {
    var baseUrl = [location.protocol, '//', location.host, location.pathname].join('');
    var url = baseUrl + '?' + this.form.serialize();
    history.pushState({
      tableTyrantId: this.id,
      formData: this.form.serializeArray()
    }, null, this._urlWithoutGimme(url));
  }

  _urlWithoutGimme(url) {
    return url.replace(/gimme=\w+&?/, '');
  }

  _urlForCsvWorkOrder(href) {
    let url = new URL(href);
    let form = this.form.serializeObject();
    form["filters-are"] = "";
    let updatedUrl = this.url(form).toString().replace("?gimme=grid", ".json?");
    return `${updatedUrl}&${url.searchParams.toString()}&gimme=new_work_order&in_format=csv`;
  }

  // actions
  resetWorkspace() {
    this.filters.reset();
    this.columns.reset();
    this.sorts.reset();
    this.updateWorkspace();
  }

  updateWorkspace() {
    this.updateWorkspaceWith({overrides: {}});
  }

  updateWorkspaceWith(opts={}) {
    this._el.addClass(opts.animate || 'refreshing');

    let url = this.url(
      opts.overrides ? {...this.form.serializeObject(), ...(opts.data || {}), ...opts.overrides} : opts.data || {},
      opts.url || this.form.attr('action'));
    url.searchParams.set('gimme', opts.gimme ?? 'grid');

    fetch(url, {headers: {'Accept': 'application/json'}})
      .then(response => response.json())
      .then(json => {
        FindAndUpdate.text(json.text_updates);
        FindAndUpdate.html(json.updates).then(() => {
          FindAndUpdate.glow(json.glows);
          opts.url && opts.pushState !== false && TableTyrant.Grid.withID(this.id).updateUrl(opts.url);
          opts.then && opts.then(json);
          this._el.removeClass(opts.animate || 'refreshing');
        });
      });
  }

  updateRows(keys, opts = {}) {
    let keyStrings = keys.map(k => k.toString());

    opts.params = opts.params || {};
    if (opts.animate) {
      this._el.find('tr.match').filter((ix, el) => keyStrings.includes(el.dataset.key)).addClass('table-tyrant--row---stale');
    }

    let selected = Array.from(this._el.find('td.selected input:checked').map((ix, e) => e.value));
    let shownKeys = Array.from(this._el.find('tr.match').map((ix, e) => e.getAttribute('data-key')));
    let findKeys = shownKeys.filter(k => keyStrings.includes(k));

    if (findKeys.length === 0) { return; } // nothing to update; don't bother
    this.updateWorkspaceWith({gimme: "row", animate: "refreshing-rows", overrides: {...opts.params, key: findKeys.join(","), "selected[]": selected}});
  }

  showDiagnostics() {
    var $diagnostics = this._el.find('.diagnostics');
    if ($diagnostics.filter(':hidden').length === 0) {
      $diagnostics.removeClass('show');
    } else {
      $diagnostics.filter(':hidden').last().addClass('show');
    }
  }

  _setupListeners() {
    if (this._el.is('.table-tyrant-listeners-setup')) return;
    this._el.addClass('table-tyrant-listeners-setup');
      
    this.columns;
    this.sorts._setup();
  }

  _makeDraggable() {
    var self = this;
    var columns = this.columns;
    if (this._el.is('.no-workspaces')) return;

    var $resultsTable = this._el.find('table.results');
    if ($resultsTable.data('is-draggable') === true) return;

    var doIt = function() {
      $resultsTable.dragtable({
        dragFooter: false,
        items: '.dragtable-drag-handle'
      });
    };

    $resultsTable.data('is-draggable', true);
    $resultsTable.on('dragtablestop', function(event, data) {
      columns.setOrder(data.order);
      self.updateWorkspaceWith({
        animate: 'saving',
        overrides: {gimme: 'popover'},
        then: json => { self.workspaces.isDirty = json.dirty },
      });
    });

    doIt();
    this._el.on('ajax:success', 'form.edit-workspace', doIt);
  }

  static closest(from) {
    return new TableTyrant.Grid($(from).closest('.table-tyrant'));
  }

  static withID(id) {
    return new TableTyrant.Grid($("#" + id));
  }

  static updateGridFromState(state) {
    TableTyrant.Grid.withID(state.tableTyrantId)
      .updateWorkspaceWith(state.updatesUrl ? {url: state.updatesUrl, pushState: false} : {data: Object.fromEntries(state.formData.map(({name, value}) => [name, value]))});
  }
}
