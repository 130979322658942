export default {
  setup() {
    $(document).on('show.bs.modal', '#new-candidate-outcome-and-demographics-report-modal-editor', event => {
      let checkboxes = event.target.querySelectorAll('input[type=checkbox]');
      let submitButton = event.target.querySelector('.modal-editor--submit-button');

      function anyCheckedCheckBoxes() {
        return event.target.querySelectorAll('input[type=checkbox]:checked').length > 0;
      }

      function toggleSubmitButton() {
        submitButton.disabled = !anyCheckedCheckBoxes()
      }

      checkboxes.forEach(checkbox => {
        checkbox.addEventListener('click', () => {
          toggleSubmitButton();
        });
      });
    });
  }
}
