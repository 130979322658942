import {FindAndUpdate} from 'services/find_and_update';
import {Components} from 'components/components';
import Rails from "@rails/ujs";

export class Hoverboard {
  static rooted({id, ...opts}) {
    let $el = $(`#${id}`);
    return $el.data('hoverboard') || new Hoverboard({id, ...opts});
  }
  
  static get current() {
    let id = $('.hoverboard.in').attr('id');
    return id ? Hoverboard.rooted({id: id}) : null;
  }
  
  constructor({id, ...opts}) {
    this.id = id;
    this.showing = false;
    this.options = opts;
    
    this.$element.data('hoverboard', this);
  }
  
  get $element() {
    let $e = $(`#${this.id}`);
    if ($e.length) { return $e; }
    if (this._$element) { return this._$element; }
    
    return this._$element = this._build();
  }
  get $dialog() { return this.$element.find('.hoverboard--dialog'); }
  get $header() { return this.$element.find('.hoverboard--header'); }
  get $body() { return this.$element.find('.hoverboard--body'); }
  get $footer() { return this.$element.find('.hoverboard--footer'); }
  
  toggle(relatedTarget) {
    this.showing ? this.hide(relatedTarget) : this.show(relatedTarget);
  }
  
  show(relatedTarget=null) {
    let e = $.Event('show.recruit.hoverboard', { relatedTarget: relatedTarget });
    this.$element.trigger(e);
    if (this.showing || e.isDefaultPrevented()) { return; }
    
    this.showing = true;
    this._handleEscape();
    
    this._backdrop(() => {
      if (relatedTarget && this.options.matchPosition) {
        this.$dialog.css({marginTop: $(relatedTarget).offset().top - window.scrollY});
      }
      
      this.updateBody();
      
      this.$element
        .attr('aria-hidden', false)
        .show();
      this.loadRemoteContent();
      setTimeout(() => {
        this.$element
          .addClass('in')
          .trigger('shown.recruit.hoverboard');
      }, 1);
    });
  }
  
  hide(relatedTarget=null) {
    let e = $.Event('hide.recruit.hoverboard', { relatedTarget: relatedTarget });
    this.$element.trigger(e);
    if (!this.showing || e.isDefaultPrevented()) { return; }
    
    const hideHoverboard = () => {
      this.showing = false;
      this._handleEscape();

      if (this.options.closeConfirmationClass) this.$element.removeClass(this.options.closeConfirmationClass);
      this.$element
        .removeClass('in')
        .attr('aria-hidden', true)
        .off('click.dismiss.hoverboard');
      this._backdrop(() => {
        this.$element.hide();
        this.updateBody();
        this.removeBackdrop();
        Hoverboard.replant(null);
        this.$element.trigger('hidden.recruit.hoverboard');
      });
    }
    this.options.closeConfirmation ? this.#closeConfirmation(hideHoverboard) : hideHoverboard();
  }
  
  updateHeader($header=this.$header) {
    $('<h3>').addClass('hoverboard--title').text(this.options.header).appendTo($header);
    $('<button type="button" class="close" data-dismiss="hoverboard" aria-label="Close">')
      .append($('<span aria-hidden="true">&times;</span></button>'))
      .appendTo($header);
  }
  
  updateBody($body=this.$body, options=this.options) {
    if (options.contentUrl === '#') { return; }
    
    $body.empty().append(options.content || Components.iconicText('sync fa-spin', options.loadingMessage || 'Loading…'));
  }
  
  updateFooter($footer=this.$footer) {
    $footer.append(this.options.footer);
  }
  
  loadRemoteContent(options=this.options) {
    if (!options.contentUrl) { return; }
    if (options.contentUrl === '#') { return; }
    
    this.$element.addClass('hoverboard---loading');
    this.$element.trigger('load.recruit.hoverboard');
    $.getJSON({url: options.contentUrl, data: options.contentParams})
      .fail(() => alert("Something went wrong"))
      .always(() => this.$element.removeClass('hoverboard---loading'))
      .then(json => {
        FindAndUpdate.all(json).then(() => {
          this.$element.trigger('loaded.recruit.hoverboard');
        });
      });
  }
  
  _build() {
    let $holder = $('<aside class="hoverboard" />')
      .attr('id', this.id)
      .attr('aria-hidden', true)
      .addClass(this.options.cssClass || '')
      .toggleClass('fade', !$('html').is('test-area'))
      .toggleClass('hoverboard--dynamic', this.options.contentUrl && this.options.contentUrl !== '#')
      .appendTo(document.body);
    
    let $dialog = $('<section class="hoverboard--dialog" />')
      .appendTo($holder);
    
    this.updateHeader(
      $('<header class="hoverboard--header" />')
        .appendTo($dialog)
    );
    this.updateBody(
      $('<article class="hoverboard--body" />')
        .appendTo($dialog)
    );
    this.updateFooter(
      $('<footer class="hoverboard--footer" />')
        .appendTo($dialog)
    );
    
    return $holder;
  }
  
  _handleEscape() {
    if (this.showing) {
      this.$element.on('keyup.dismiss.recruit.hoverboard', (e) => {e.which === 27 && this.hide()});
    } else if (!this.showing) {
      this.$element.off('keyup.dismiss.recruit.hoverboard');
    }
  }
  
  _handleClosing() {
    this.$element.on('click.dismiss.hoverboard', (e) => {
      if (e.target !== e.currentTarget) return; // click outside dialog closes
      this.hide();
    }).on('click.dismiss.hoverboard', '[data-dismiss=hoverboard]', (e) => { // click on close button closes
      this.hide();
    });
  }

  #closeConfirmation(whenConfirmed) {
    if (this.options.closeConfirmationClass && !this.$element.hasClass(this.options.closeConfirmationClass)) return whenConfirmed();

    Components.Modal.confirm({
      id: 'hoverboard-close-confirmation',
      title: this.options.closeConfirmationTitle || 'Are you sure?',
      body: this.options.closeConfirmationBody || 'Are you sure you want to leave this page?',
      confirm: this.options.closeConfirmationConfirm || 'Leave page',
      confirmClass: this.options.closeConfirmationConfirmClass || 'btn-warning',
      cancel: this.options.closeConfirmationCancel || 'Cancel',
      whenConfirmed: modal => whenConfirmed(),
    });
  }

  _backdrop(callback) {
    var animate = this.$element.hasClass('fade') ? 'fade' : '';
    
    if (this.showing) {
      var doAnimate = $.support.transition && animate;

      this._$backdrop = $('<div class="hoverboard-backdrop ' + animate + '" />')
        .appendTo(document.body);

      this._handleClosing();

      if (doAnimate) { this._$backdrop[0].offsetWidth; } // force reflow
      this._$backdrop.addClass('in');

      this._backdropTransitionEnd(callback, doAnimate);
    } else if (!this.showing && this._$backdrop) {
      this._$backdrop.removeClass('in');

      this._backdropTransitionEnd(callback, $.support.transition && this.$element.hasClass('fade'));
    } else if (callback) {
      callback();
    }
  }
  
  _backdropTransitionEnd(callback, wait) {
    if (wait && callback) {
      this._$backdrop
        .one($.support.transition.end, callback)
        .emulateTransitionEnd(300)
    } else if (callback) {
      callback();
    }
  }
  
  removeBackdrop() {
    this._$backdrop && this._$backdrop.remove();
    this._$backdrop = null;
  }
  
  static replant(root) {
    let $oldHolder = $('.hoverboard-modal-holder');
    let $newHolder = $('<div class="hoverboard-modal-holder">')
      .data('hoverboard-root', root);
    let transferredIds = [];
    let transferAll = (xx, els) => {
      els.each((ix, el) => {
        if ($.inArray(el.id, transferredIds) == -1) {
          if (!$(el).is(".hoverboard-transplant-exception")) {
            transferredIds.push(el.id);
            $newHolder.append(el);
          }
        }
      });
    };
    
    transferAll('modal editors', $(root).find('.modal-editor'));
    transferAll('hoverboard-aware content', $(root).find('.hoverboard-transplant-root'));
    transferAll('modals', $(root).find('.modal'));
    if (root) { transferAll('existing', $oldHolder.children()); }
    
    $newHolder.appendTo(document.body)
    $oldHolder.addClass('hoverboard-modal-old-holder').removeClass('hoverboard-modal-holder').find('*[id]').removeAttr('id');
    setTimeout(() => $oldHolder.remove());
  }
}

export var uiHoverboard = {
  setup: function() {
    let $doc = $(document);
    
    this.addToggleHandler($doc);
    this.addFocusHandlers($doc);
    this.addScrollHandlers($doc);
    this.addReplantingHandlers($doc);
    this.addAutoUpdateHandlers($doc);
    
    this.autoOpen();
  },
  
  addToggleHandler($doc) {
    $doc.on('click', '[data-toggle=hoverboard]', function(event) {
      if (event.metaKey) { return }
      
      event.preventDefault();
      
      let $trigger = $(event.currentTarget);
      let href = $trigger.attr('href');
      let contentUrl = $trigger.data('contentUrl');
      let targetSelector = $trigger.data('target') || (href && href.replace(/.*(?=#[^\s]+$)/, '')); //strip for ie7
      let $target = $(targetSelector);
      
      Hoverboard.rooted($.extend(
        contentUrl ? {contentUrl} : ($trigger.data('content') ? {} : {contentUrl: href, contentParams: $trigger.data('hoverboard-params')}),
        $target.data(),
        $trigger.data(),
        {id: targetSelector.replace(/^#/, '')}
      )).toggle($trigger);
    });
  },
  
  addFocusHandlers($doc) {
    let doFocus = () => {
      $('.hoverboard.in  :focusable').not('.no-autofocus, .no-autofocus *').eq(1).focus();
    };
    
    $doc
      .on('shown.recruit.hoverboard loaded.recruit.hoverboard', '.hoverboard', doFocus)
      .on('hidden.bs.modal', '.modal', doFocus);
  },
  
  addScrollHandlers($doc) {
    $doc
      .on('show.recruit.hoverboard', '.hoverboard', () => $(document.body).addClass('hoverboard-open'))
      .on('hidden.recruit.hoverboard', '.hoverboard', () => $(document.body).removeClass('hoverboard-open'));
  },
  
  addReplantingHandlers($doc) {
    $doc
      .on('find_and_update', '.hoverboard', (e) => Hoverboard.replant(e.currentTarget));
  },
  
  addAutoUpdateHandlers($doc) {
    let isAutoUpdating = (event) => $(event.target).is('.hoverboard--autoupdate-trigger');
    let triggerAutoUpdates = (hb) => hb.querySelectorAll('.hoverboard--autoupdate-trigger').forEach(t => Rails.fire(t, "click"));
    
    $doc.on('ajax:success', '.hoverboard-modal-holder', (event) => {
      if (!isAutoUpdating(event)) {
        triggerAutoUpdates($(event.currentTarget).data('hoverboard-root'));
      }
    }).on('ajax:success', '.hoverboard', (event) => {
      if (!isAutoUpdating(event)) {
        triggerAutoUpdates(event.currentTarget);
      }
    });
  },
  
  autoOpen() {
    let $trigger = $(`[data-toggle=hoverboard][data-target="${document.location.hash}"]`);
    if ($trigger.length) {
      $trigger.click();
    } else {
      let $target = $((document.location.hash || 'match-nothing') + ".hoverboard");
      if ($target.length) { Hoverboard.rooted({id: $target}).show(); }
    }
  }
};
